import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Button from '../components/Button';
import Stack from '../components/Stack';
import links from '../data/links';
import styles from './index.module.scss';

const { bot, vote } = links;
const buttonProps = ({ icon, href, label }) => ({
  icon: { left: icon },
  target: '_blank',
  rel: 'noreferrer',
  children: label,
  as: 'a',
  href,
});

export default function IndexPage() {
  const { file } = useStaticQuery(query);

  return (
    <Layout title='Home' className={styles.layout}>
      <Container
        as='section'
        containerClassName={styles.container}
        className={styles[`container--content`]}
      >
        <Stack className={styles.content} direction='column' spacing='element'>
          <h1 className={styles.title}>PokeMeow</h1>
          <p className={styles.text}>
            Catch, evolve, and trade Pokémon, battle with friends, and more! All completely free.
          </p>
        </Stack>
        <Stack className={styles.actions}>
          <Button {...buttonProps(bot)} variant='solid' color='primary' />
          <Button {...buttonProps(vote)} />
        </Stack>
        <div className={styles.background}>
          <Img fluid={file.childImageSharp.fluid} />
        </div>
      </Container>
    </Layout>
  );
}

const query = graphql`
  {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
